import api from '@/libs/api'
import apiRoutes from '@/router/apiRoutes'

export const insertUserSupportItem = formData => new Promise((resolve, reject) => {
  api
    .post(apiRoutes.support, formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
