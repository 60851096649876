<template>
  <section class="support">
    <b-container>
      <b-card
        class="p-4"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <h2 class="h2-titles">
              Ficou com alguma dúvida?
            </h2>

            <h5 class="h5-titles">
              Tem alguma sugestão?
              Presenciou algum mal funcionamento?
              Entre em contato conosco usando o formulário a baixo ou você pode nos ligar,
              mandar email ou até chamar no WhatsApp ;)
            </h5>
          </b-col>
        </b-row>

        <validation-observer
          ref="supportForm"
        >
          <div
            v-if="loading"
            class="spinner-area"
          >
            <b-spinner />
          </div>

          <b-form
            v-if="!loading"
          >
            <b-row>
              <b-col
                lg="6"
              >
                <b-form-group
                  class="mb-3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nome"
                    rules="required"
                  >
                    <input
                      id="name"
                      v-model="formData.name"
                      :class="errors.length > 0 ? 'border-danger' : ''"
                      name="name"
                      placeholder="Nome"
                      autocomplete="off"
                    >

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  class="mb-3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="E-mail"
                    rules="required|email"
                  >
                    <input
                      id="email"
                      v-model="formData.email"
                      :class="errors.length > 0 ? 'border-danger' : ''"
                      name="email"
                      placeholder="E-mail"
                      autocomplete="off"
                    >

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  class="mb-3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Celular"
                    rules="required"
                  >
                    <input
                      id="email"
                      v-model="formData.phone"
                      v-mask="'(##) #####-####'"
                      :class="errors.length > 0 ? 'border-danger' : ''"
                      name="email"
                      placeholder="Telefone"
                      autocomplete="off"
                    >

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="6"
              >
                <b-form-group
                  class="mb-3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Mensagem"
                    rules="required"
                  >
                    <b-form-textarea
                      id="message"
                      v-model="formData.message"
                      :class="errors.length > 0 ? 'border-danger' : ''"
                      placeholder="Sua Mensagem"
                      auto-complete="off"
                      name="message"
                      rows="7"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              class="mt-3"
            >
              <b-col
                cols="12"
              >
                <button-form
                  component-mode="outline"
                  button-class="me-3 mb-3"
                  @action="clearFormData"
                >
                  Cancelar
                </button-form>

                <button-form
                  @action="handleFormSubmit"
                >
                  Enviar
                </button-form>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-container>
  </section>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BForm,
  BCard,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'

import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import {
  required,
  email,
} from '@/utils/validations/validations'

import ButtonForm from '@/components/Buttons/ButtonForm.vue'
import { insertUserSupportItem } from '@/utils/requests/support'
import { successMessage, warningMessageToast } from '@/libs/sweetalerts'
import { messages } from '@/utils/validations/messages'

export default {
  name: 'Support',

  components: {
    BContainer,
    BRow,
    BCol,
    BForm,
    BCard,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    ButtonForm,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      required,
      email,

      loading: false,

      formData: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
    }
  },

  methods: {
    async handleFormSubmit() {
      const result = new Promise((resolve, reject) => {
        this.$refs.supportForm.validate()
          .then(success => {
            if (success) {
              resolve(true)
            }
          })
          .catch(() => {
            reject()
          })
      })

      if (await result) {
        this.loading = true

        await this.handleSendInfoToSupport()

        this.loading = false
      }
    },

    async handleSendInfoToSupport() {
      await insertUserSupportItem(this.formData)
        .then(response => {
          if (response.status === 204) {
            this.clearFormData()
            successMessage(
              messages.successSendMessageInfo,
              messages.successSendMessage,
            )
          }
        })
        .catch(() => {
          warningMessageToast(messages.impossible)
        })
    },

    clearFormData() {
      this.formData = {
        name: '',
        email: '',
        phone: '',
        message: '',
      }
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
